<template>
  <div>
    <div class='flex flex-col lg:flex-row gap-y-4'>
      <div class='flex flex-col gap-y-4 lg:gap-y-8 w-full lg:w-1/4'>
        <div class='text-2xl lg:text-3xl font-semibold uppercase '>
          평점 확인 <span class='text-xs text-red-500'>* Only for Korean</span>
        </div>
        <div v-if='!hideTotalScore'
          class='w-full lg:w-40 h-28 lg:h-40 text-white flex flex-col justify-center items-start p-8'
          :style='`background-color: ${eventMainThemeColor};`'>
          <div class='text-lg uppercase font-medium '>총 이수 평점</div>
          <div class='text-5xl font-normal'>{{totalScore}}점</div>
        </div>
      </div>
      <div class='flex flex-col gap-y-4 lg:gap-y-8 w-full lg:w-3/4'>
        <div>
          <h3 class='text-lg text-gray-800 font-medium'>평점 관련 유의사항</h3>
          <p class='text-sm text-gray-700 font-normal pr-4'>
            온라인 이수 시간은 평점 인정 강의 내 출석체크 버튼 클릭 이후 동영상 시청 시간의 총합으로 산정합니다.<br>
            본 학회의 평점은 평점 규정에 따라 산정 될 예정입니다. 평점 인정 기준을 숙지하시어 평점 발급에 어려움이 없으시길 바랍니다.<br><br><br>
            추후 평점 관련 문의 사항은 대한부정맥학회로 연락주시기 바랍니다.<br><br><br>
            <span class=' text-red-500 font-medium'>* 최종 이수 평점 확인: 최종 이수 평점은 보고-승인 단계를 거쳐 최대 2달 뒤 각 협/학회 Site 내에서 본인 확인 가능</span>
          </p>
        </div>
        <div>
          <table v-if='!noWatchVideoData' class='table-fixed w-full text-sm lg:text-base'>
            <thead>
              <tr class='uppercase text-gray-600'>
                <th class='py-2 lg:py-4 px-2 text-left w-6 lg:w-8 whitespace-nowrap align-top'></th>
                <th class='py-2 lg:py-4 px-2 text-left w-32 lg:w-64 whitespace-nowrap align-top'>날짜</th>
                <th class='py-2 lg:py-4 px-2 text-right'>온라인 이수 시간</th>
                <th class='py-2 lg:py-4 px-2 text-right' v-if='showScore'>온라인 이수 평점</th>
                <th class='py-2 lg:py-4 px-2 text-right' v-if='showScore'>현장 이수 평점</th>
              </tr>
            </thead>
            <khrs-custom-my-page-watched-videos v-for='(dayData, index) in myDataByDay' :key='`data-for-day-${index}`'
              :data-watched-videos-for-day='dayData' :show-score='showScore' />
          </table>
          <div v-else class='my-2 py-16 bg-gray-100 rounded-md text-gray-600 text-center'>
            No videos have been watched yet.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import isEmpty                 from 'lodash/isEmpty'
import KhrsCustomMyPageWatchedVideos from '@/components/my-page/KhrsCustomMyPageWatchedVideos.vue'
import ScoreCalculationHelpers       from '@/utils/score-calculation-helpers'
import DateTimeStringHelpers         from '@/utils/date-time-string-helpers'

export default {
  name: 'KhrsCustomMyPageScore',
  components: {
    KhrsCustomMyPageWatchedVideos,
  },
  props: [
    'hideTotalScore',
  ],
  computed: {
    ...mapGetters('myPages', [
      'myDataByDay',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'eventMainThemeColor',
    ]),
    showScore () {
      return false
    },
    noWatchVideoData () {
      return isEmpty(this.myDataByDay)
    },
    totalScore () {
      return Object.keys(this.myDataByDay).map((key) => {
        let total = 0.0
        this.myDataByDay[key].forEach(session => {
          total = total + parseFloat(session.totalPlayedTime)
        })
        let watchedMinute = Math.floor(total / 60)
        return ScoreCalculationHelpers.calcedScore(this.showingEventId, DateTimeStringHelpers.toDate(key) ,watchedMinute)
      }).reduce((a, b) => a + b, 0)
    },
  },
}
</script>
