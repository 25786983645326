<template>
  <div class='px-2 lg:px-0'>
    <my-page-profile v-if='includeProfile'
      :profile-helper-message='profileHelperMessage'
      class='mt-12 mb-24'/>
    <my-page-certificates v-if='includeCertificates' class='mt-12 mb-24' />
    <my-page-watched-time v-if='includeWatchedTime' class='mt-12 mb-24' />
    <khrs-custom-my-page-score v-if='includeScore' :hide-total-score='hideTotalScore' class='mt-12 mb-24' />
    <my-page-stamp v-if='includeStamp' class='mt-12 mb-24' />
    <my-page-quiz v-if='includeQuiz' class='mt-12 mb-24' />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MyPageProfile      from '@/components/my-page/MyPageProfile.vue'
import MyPageCertificates from '@/components/my-page/MyPageCertificates.vue'
import KhrsCustomMyPageScore from '@/components/my-page/KhrsCustomMyPageScore.vue'
import MyPageWatchedTime  from '@/components/my-page/MyPageWatchedTime.vue'
import MyPageStamp        from '@/components/my-page/MyPageStamp.vue'
import MyPageQuiz         from '@/components/my-page/MyPageQuiz.vue'

export default {
  name: 'MyPage',
  components: {
    MyPageProfile,
    MyPageCertificates,
    KhrsCustomMyPageScore,
    MyPageWatchedTime,
    MyPageStamp,
    MyPageQuiz
  },
  props: [
    'includeProfile',
    'profileHelperMessage',
    'includeCertificates',
    'includeWatchedTime',
    'includeScore',
    'hideTotalScore',
    'includeStamp',
    'includeQuiz'
  ],
  methods: {
    ...mapActions('myPages', [
      'getMyPageData'
    ]),
  },
  mounted () {
    this.getMyPageData()
  }
}
</script>

<style lang='scss' scoped>
  .logout-button {
    transition: all 0.3s ease-in-out;
  }
</style>
