<template>    
  <div class='flex flex-col lg:flex-row justify-center lg:justify-start gap-y-4 lg:gap-y-0 lg:gap-x-8'>
    <div class='lg:mt-6 w-full lg:w-1/4 flex flex-col justify-start gap-y-4'>
      <div>
        <h1 class='font-semibold uppercase text-2xl lg:text-3xl'>
          {{ myPageProfileTitle }}
        </h1>
        <p class='mt-2 text-sm text-gray-700 font-normal pr-4'>
          {{ profileHelperMessage }}
        </p>
      </div>
      <button v-if='includeLogoutButton'
        class='logout-button w-full lg:w-32 uppercase border px-8 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
        @click='logoutApp'>
        Logout
      </button>
    </div>
    <div class='px-8 py-8 rounded-lg flex-grow' :style='backgroundColorStyle'>
      <div class='mb-4 flex flex-col lg:flex-row items-start lg:items-centered justify-start'>
        <div 
          class='font-semibold w-48'
          :style='themeTextColorStyle'>
          <user-icon class='hidden lg:inline-block mr-2' />
          {{ myPageProfileNameTitle }}
        </div>
        <div>
          {{myPageProfileShowingNameString}}
        </div>
      </div>
      <div
        class='mb-4 flex flex-col lg:flex-row items-start lg:items-centered justify-start'>
        <div 
          class='font-semibold w-48'
          :style='themeTextColorStyle'>
          <mail-icon class='hidden lg:inline-block mr-2' /> 
          {{ myPageProfileEmailTitle }}
        </div>
        <div>
          {{myPageDataProfile.email}}
        </div>
      </div>
      <div
        class='mb-4 flex flex-col lg:flex-row items-start lg:items-centered justify-start'>
        <div 
          class='font-semibold w-48'
          :style='themeTextColorStyle'>
          <phone-icon class='hidden lg:inline-block mr-2' /> 
          Phone Number
        </div>
        <div>
          {{myPageDataProfile.phoneNumber}}
        </div>
      </div>
<!--       <div
        class='mb-4 flex flex-col lg:flex-row items-start lg:items-centered justify-start'>
        <div 
          class='font-semibold w-48'
          :style='themeTextColorStyle'>
          <home-icon class='hidden lg:inline-block mr-2' /> 
          주소
        </div>
        <div>
          {{profile.address}}, {{profile.addressDetail}}
        </div>
      </div> -->
      <div v-if='myPageDataProfile.licenseNumber'
        class='mb-4 flex flex-col lg:flex-row items-start lg:items-centered justify-start'>
        <div 
          class='font-semibold w-48'
          :style='themeTextColorStyle'>
          <certificate-icon class='hidden lg:inline-block mr-2' /> 
          {{ myPageProfileLicenseNumberTitle }}
        </div>
        <div>
          {{myPageDataProfile.licenseNumber}}
        </div>
      </div>
      <div v-if='myPageDataProfile.hospitalName'
        class='mb-4 flex flex-col lg:flex-row items-start lg:items-centered justify-start'>
        <div 
          class='font-semibold w-48'
          :style='themeTextColorStyle'>
          <officeBuilding-icon class='hidden lg:inline-block mr-2' /> 
          {{ myPageProfileAffiliationTitle }}
        </div>
        <div>
          {{myPageDataProfile.hospitalName}}
        </div>
      </div>
<!--       <div>
        <div
          class='mb-4 flex flex-col lg:flex-row items-start lg:items-centered justify-start'>
          <div 
            class='font-semibold w-48'
            :style='themeTextColorStyle'>
            <clock-icon class='hidden lg:inline-block mr-2' /> 
            등록 형태
          </div>
          <div>
            {{registrationDataTypeString}}
          </div>
        </div>
      </div>
      <div>
        <div
          class='mb-4 flex flex-col lg:flex-row items-start lg:items-centered justify-start'>
          <div 
            class='font-semibold w-48'
            :style='themeTextColorStyle'>
            <book-2-icon class='hidden lg:inline-block mr-2' /> 
            인쇄본 교재 구매
          </div>
          <div>
            {{buyPrintedTextbookString}}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { 
  MailIcon, 
  OfficeBuildingIcon 
} from '@vue-hero-icons/outline'
import { 
  CertificateIcon,
  UserIcon,
  // ClockIcon,
  PhoneIcon,
  // Book2Icon,
  // HomeIcon,
} from 'vue-tabler-icons'
import { 
  mapGetters, mapState, mapActions 
} from 'vuex'

export default {
  name: 'MyPageProfile',
  components: {
    MailIcon,
    OfficeBuildingIcon,
    CertificateIcon,
    UserIcon,
    // ClockIcon,
    PhoneIcon,
    // Book2Icon,
    // HomeIcon,
  },
  props: [
    'profileHelperMessage',
  ],
  computed: {
    ...mapState('users', [
      'registrationData',
      'profile',
    ]),
    ...mapGetters('events', [
      'showingEventMyPageConfigurations',
      'eventMainThemeColor',
      'themeTextColorStyle',
      'colorsInRange',
    ]),
    ...mapGetters('myPages', [
      'myPageDataProfile',
    ]),
    myPageProfileConfigs () {
      return this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageProfileConfigs ? this.showingEventMyPageConfigurations.myPageProfileConfigs : null
    },
    myPageProfileTitle () {
      return this.myPageProfileConfigs && this.myPageProfileConfigs.title ? this.myPageProfileConfigs.title : 'My Profile'
    },
    myPageProfileNameTitle () {
      return this.myPageProfileConfigs && this.myPageProfileConfigs.nameTitle ? this.myPageProfileConfigs.nameTitle : 'Name'
    },
    myPageProfileEmailTitle () {
      return this.myPageProfileConfigs && this.myPageProfileConfigs.emailTitle ? this.myPageProfileConfigs.emailTitle : 'Email Address'
    },
    myPageProfileLicenseNumberTitle () {
      return this.myPageProfileConfigs && this.myPageProfileConfigs.licenseNumberTitle ? this.myPageProfileConfigs.licenseNumberTitle : 'License Number'
    },
    myPageProfileAffiliationTitle () {
      return this.myPageProfileConfigs && this.myPageProfileConfigs.affiliationTitle ? this.myPageProfileConfigs.affiliationTitle : 'Affiliation'
    },
    myPageProfileShowingNameColumn () {
      return this.myPageProfileConfigs && this.myPageProfileConfigs.showingNameColumn ? this.myPageProfileConfigs.showingNameColumn : 'name'
    },
    myPageProfileShowingNameString () {
      return this.myPageProfileShowingNameColumn === 'name' ? this.myPageDataProfile.name : this.myPageDataProfile.firstName + ' ' + this.myPageDataProfile.lastName
    },
    buyPrintedTextbookString () {
      return this.registrationData.buyPrintedTextbook === 'buy_printed_textbook' ? '구매' : '비구매'
    },
    includeLogoutButton () {
      return this.myPageProfileConfigs && this.myPageProfileConfigs ? this.myPageProfileConfigs.includeLogoutButton : false
    },
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    backgroundColorStyle () {
      return `background-color: ${this.colorsInRange[0]}; border: solid 1px ${this.colorsInRange[1]}`
    },
    hasRegistrationData () {
      return this.registrationData.payment
    },
    registrationDataTypeString () {
      let registrationType = this.registrationData.registrationType === 'online_72hours' ? '온라인 72시간' : '온라인 2주'
      let paymentStatus = this.registrationData.payment ? '' : '등록비 입금 여부 확인 중'
      if (this.registrationData.registrationType) {
        if (!this.registrationData.payment) {
          return `${registrationType} (${paymentStatus})`
        } else {
          return `${registrationType}`
        }
      }
      return '-'
    },
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'getRegistrationData',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
  },
  mounted () {
    this.getRegistrationData()
  },
}
</script>

<style lang='scss' scoped>
  .logout-button {
    transition: all 0.3s ease-in-out;
  }
</style>
