<template>
  <tbody class='border-t'>
    <tr class='hover:bg-gray-50 cursor-pointer hover:shadow-md' @click='toggleOpenDetail'>
      <td class='py-2 lg:py-4 px-2 uppercase w-12'>
        <chevron-double-right-icon size='1.2x' />
      </td>
      <td class='py-2 lg:py-4 px-2 uppercase font-semibold'>{{dateForStuff}}<span class='text-sm'>(상세 시간 보기)</span></td>
      <td class='py-2 lg:py-4 px-2 text-right'>{{videoWatchedTimeString(myDailyWatchedVideosTotalTime)}}</td>
      <td class='py-2 lg:py-4 px-2 text-right' v-if='showScore'>{{ dailyScore }}점</td>
      <td class='py-2 lg:py-4 px-2 text-right text-xs text-gray-500' v-if='showScore'>행사 종료 후 업데이트 예정</td>
    </tr>
    <tr v-for='session in watchedVideoDetailedData' :key='`session-${session.sessionId}`' class='text-sm'>
      <td class='py-2 px-2 uppercase w-12'></td>
      <td class='py-2 px-2 text-sm'>{{session.title}}</td>
      <td class='py-2 px-2 text-right'>{{videoWatchedTimeString(session.totalPlayedTime)}}</td>
      <td class='py-2 px-2 text-right' v-if='showScore'></td>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChevronDoubleRightIcon } from '@vue-hero-icons/outline'
import DateTimeStringHelpers   from '@/utils/date-time-string-helpers'
import ScoreCalculationHelpers from '@/utils/score-calculation-helpers'

export default {
  name: 'KhrsCustomMyPageWatchedVideos',
  props: [
    'dataWatchedVideosForDay',
    'showScore',
  ],
  data () {
    return {
      showDetails: false,
    }
  },
  components: {
    ChevronDoubleRightIcon
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId'
    ]),
    watchedVideoDetailedData () {
      return (this.showDetails) ? this. dataWatchedVideosForDay : []
    },
    myDailyWatchedVideosTotalTime () {
      let total = 0.0
      this.dataWatchedVideosForDay.forEach(session => {
        total = total + parseFloat(session.totalPlayedTime)
      })
      return total
    },
    dailyScore () {
      let watchedMinute = Math.floor(this.myDailyWatchedVideosTotalTime / 60)
      return ScoreCalculationHelpers.calcedScore(this.showingEventId, DateTimeStringHelpers.toDate(this.dataWatchedVideosForDay[0].scheduledStartTime), watchedMinute)
    },
    dateForStuff () {
      return DateTimeStringHelpers.toDateWithDay(this.dataWatchedVideosForDay[0].scheduledStartTime)
    }
  },
  methods: {
    videoWatchedTimeString (date) {
      return DateTimeStringHelpers.secondToHourAndMinute(date)
    },
    toggleOpenDetail () {
      this.showDetails = !this.showDetails
    },
  },
}
</script>

